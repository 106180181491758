<template>
    <div class="panel panel-default">
        <div class="row panel-body">
            <div class="col-md-6 col-xs-12" v-if="collapseForm">
                <label :class="`${(permission) ? 'cdi_link' : ''} object_detail`" @click="populate(shipper, 'form', 'shipper')">3rd Party Shipper</label><br />
                {{ shipper.addressName }}<br />
                {{ shipper.address }}<br />
                {{ shipper.city }}, {{ shipper.state }} {{ shipper.zip }}
            </div>
            <div class="col-md-6 col-xs-12" v-if="collapseForm">
                <label :class="`${(permission) ? 'cdi_link' : ''} object_detail`" @click="populate(shipTo, 'form', 'shipTo')">Ship to Address</label><br />
                {{ shipTo.addressName }}<br />
                {{ shipTo.address }}<br />
                {{ shipTo.city }}, {{ shipTo.state }} {{ shipTo.zip }}
            </div>

            <div class="col-xs-12 spacing_btm" v-if="!collapseForm">
                <div class="row">
                    <div class="col-md-6 col-sm-12 form-group">
                        <label>Address Name</label>
                        <input type="text" class="form-control" v-model="form.addressName.val">
                    </div>
                    <div class="col-md-6 col-sm-12 form-group">
                        <label>Address</label>
                        <textarea class="form-control" v-model="form.address.val"></textarea>
                    </div>
                    <div class="col-md-4 col-sm-12 form-group">
                        <label>City</label>
                        <Typeahead ref="city"
                                   :displayValue="form.city.val"
                                   :acceptPlainText="true"
                                   @valueChange="captureTypeAheadText"
                                   @objectSelected="captureTypeAheadText"
                                   cdi_type="city"
                        />
                    </div>
                    <div class="col-md-4 col-sm-12 form-group">
                        <label>State</label>
                        <select class="form-control" v-model="form.state.val">
                            <option v-for="[abbr, state] in cache.stateCache.STATES" v-bind:key="abbr" :value="abbr">{{ state }}</option>
                        </select>
                    </div>
                    <div class="col-md-4 col-sm-12 form-group">
                        <label>Zip</label>
                        <input type="text" ref="zip" class="form-control" v-model="form.zip.val" @keyup="onZipKeyup">
                    </div>
                    <div class="col-sm-12">
                        <button class="btn btn-default" @click="saveAddress">{{ buttonLabel }}</button>
                        <button class="btn btn-default" @click="collapseForm = !collapseForm">Cancel</button>
                    </div>
                  <ConfirmBox
                      v-if="confirmBox"
                      :heading="'Save Address'"
                      :body="'Note that changing the address after order has been placed can affect manufacturing of this order. Please confirm CDI Location is correct. '"
                      @close="saveAddress"
                  />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import stateCache from '@/cache/state.cache';
import { store } from '@/store/BusinessObject.store';
import helpers from "@/store/helpers";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import ConfirmBox from "@/components/utils/ConfirmBox.vue";

export default {
    name: 'ShipmentAddresses',
    props: ['values', 'permission'],
    components:{
      ConfirmBox,
        Typeahead
    },
    data() {
        return {
            confirmBox: false,
            shipper: {
                addressName: '',
                address: '',
                city: '',
                state: '',
                zip: ''
            },
            shipTo: {
                addressName: '',
                address: '',
                city: '',
                state: '',
                zip: ''
            },
            form: {
                addressName: {val: '', qsField: '[address_name]'},
                address: {val: '', qsField: '[address]'},
                city: {val: '', qsField: '[city]'},
                state: {val: '', qsField: '[state]'},
                zip: {val: '', qsField: '[zip]'}
            },
            collapseForm: true,
            cache: {
                stateCache: stateCache
            },
            currentForm: ''
        }
    },
    methods: {
        onZipKeyup(event) {
            if (event.key === 'Backspace') {
                this.form.city.val = '';
            }
        },
      confirmSave: function() {
        this.confirmBox = true;
      },
        captureTypeAheadText: function(obj) {
            this.form.city.val = obj.value;
        },
        populate: function(values, object, formType = '') {
            if (object === 'form') {
                this.currentForm = formType
                if (this.permission) {
                    this.collapseForm = !this.collapseForm;
                }
                this[object].addressName.val = values.addressName;
                this[object].address.val = values.address;
                this[object].city.val = values.city;
                this[object].state.val = values.state;
                this[object].zip.val = values.zip;
            } else {
                this[object].addressName = values.addressName;
                this[object].address = values.address;
                this[object].city = values.city;
                this[object].state = values.state;
                this[object].zip = values.zip;
            }

        },
        saveAddress: function() {
            if ( this.form.address.val == '' || this.form.city.val == '' || this.form.state.val == '' || this.form.zip.val == '') {
                this.$appStore.errorBox('Missing required field');
            }else {
                store.apiWithObjectLoad(`save_${this.currentForm.toLowerCase()}_address`, helpers.getQSPairs(this.form))
                this.collapseForm = !this.collapseForm;
            }
        }
    },
    watch: {
        values: function() {
            this.populate(this.values.shipperAddress, 'shipper');
            this.populate(this.values.shipToAddress, 'shipTo');
        }
    },
    computed: {
        buttonLabel: function() {
            if (this.currentForm === 'shipper') {
                return 'Save 3rd Party Shipper';
            } else if (this.currentForm === 'shipTo') {
                return 'Save Ship to Address';
            } else {
                return 'Save';
            }
        }
    }
}

</script>