<template>
    <div class="panel panel-default">
        <div class="row panel-body details_container">
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsTypeahead
                    :val="data.distributor_id"
                    :field="{
                        name: 'distributor_id',
                        label: 'Customer',
                        display: data.distributor,
                        cdiType: 'search_my_users',
                        link: userLink,
                        permission: lockRules
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsLink
                    :val="data.company_id"
                    :field="{
                        name: 'company_id',
                        label: 'Company',
                        display: data.company_name,
                        link: 'company'
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="data.distributor_po"
                    :field="{
                        name: 'distributor_po',
                        label: 'Customer PO',
                        permission: lockRules
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="data.contractor_po"
                    :field="{
                        name: 'contractor_po',
                        label: 'Contractor PO',
                        permission: lockRules
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="data.job_tag"
                    :field="{
                        name: 'job_tag',
                        label: 'Job Tag',
                        permission: lockRules
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="data.sales_order_num"
                    :field="{
                        name: 'sales_order_num',
                        label: 'Sales Order #',
                        permission: lockRules
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsSelect
                    :val="data.cdi_location_id"
                    :field="{
                        name: 'cdi_location_id',
                        label: 'CDI Location',
                        options: cache.cdiLocationCache.CDI_LOCATIONS,
                        permission: lockRules
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="data.priority_code"
                    :field="{
                        name: 'priority_code',
                        label: 'Priority',
                        permission:  (data.permissions && data.permissions.priority_code || lockRules)
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic
                    :val="data.tracker_date"
                    :field="{
                        name: 'tracker_date',
                        label: 'Order Date',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic
                    :val="data.scheduled_ship"
                    :field="{
                        name: 'scheduled_ship',
                        label: 'Scheduled Ship',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic
                    :val="data.actual_ship"
                    :field="{
                        name: 'actual_ship',
                        label: 'Actual Ship',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic
                    :val="data.sch_receive_ship"
                    :field="{
                        name: 'sch_receive_ship',
                        label: 'Scheduled Delivery',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsStatic
                    :val="data.receive_ship"
                    :field="{
                        name: 'receive_ship',
                        label: 'Delivered',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsLink
                    :val="data.quote_id"
                    :field="{
                        name: 'quote_id',
                        label: 'Quote ID',
                        link: 'quote',
                        display: data.quote_id
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsButton
                    :val="data.sales_users"
                    :field="{
                        name: 'sales_users',
                        label: 'Sales Reps',
                        display: data.sales_users,
                        buttonLabel: 'Refresh Salespeople',
                        endPoint: 'refresh_salespeople',
                        refresh: true,
                        permission: lockRules
                    }"
                    @post="post"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsDate
                    :val="data.eng_due_date"
                    :field="{
                        name: 'eng_due_date',
                        label: 'Engineering Due Date',
                        permission: (data.permissions && data.permissions.eng_due_date || lockRules)
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsCheckbox
                    :val="data.measured"
                    :field="{
                        name: 'measured',
                        label: 'Measured',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsCheckbox
                    :val="data.has_special_materials"
                    :field="{
                        name: 'has_special_materials',
                        label: 'Special Materials',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsCheckbox
                    :val="data.hold"
                    :field="{
                        name: 'hold',
                        label: 'Hold Production',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsCheckbox
                    :val="data.stock"
                    :field="{
                        name: 'stock',
                        label: 'CDI Stock',
                    }"
                    @save="save"
                />
            </div>
            <div class="col-lg-4 col-sm-6 col-xs-12">
                <DetailsText
                    :val="data.stamp_cost"
                    :field="{
                        name: 'stamp_cost',
                        label: 'Stamp Charges',
                    }"
                    @save="save"
                />
            </div>
        </div>
    </div>
</template>

<script>


import DetailsTypeahead from "@/components/businessObjects/utils/DetailsTypeahead";
import {store} from "@/store/BusinessObject.store";
import DetailsLink from "@/components/businessObjects/utils/DetailsLink";
import DetailsText from "@/components/businessObjects/utils/DetailsText";
import DetailsSelect from "@/components/businessObjects/utils/DetailsSelect";
import cdiLocationCache from "@/cache/cdiLocation.cache"
import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
import DetailsButton from "@/components/businessObjects/utils/DetailsButton";
import DetailsDate from "@/components/businessObjects/utils/DetailsDate";
import DetailsCheckbox from "@/components/businessObjects/utils/DetailsCheckbox";

export default {
    name: 'Details',
    props: ['data'],
    components: {
        DetailsCheckbox,
        DetailsStatic,
        DetailsSelect,
        DetailsText,
        DetailsLink,
        DetailsTypeahead,
        DetailsButton,
        DetailsDate
    },
    data() {
        return {
            cache: {
                cdiLocationCache
            }
        }
    },
    methods: {
        save: function(data) {
            store.save(data.field, data.val);
        },
        post: function(args) {
            store.apiWithObjectLoad(args.endPoint, args.postValue);
        }
    },
    computed: {
        isStaff: function() {
            return this.$appStore.isStaff();
        },
        userLink: function() {
            return (this.isStaff ? 'user' : '');
        },
        lockRules: function() {
            if (!this.data.permissions) {
                return false;
            }
            return (!this.data.permissions.read_only && !this.data.locked);
        }
    }

}

</script>