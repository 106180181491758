var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"row panel-body details_container"},[_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsTypeahead',{attrs:{"val":_vm.data.distributor_id,"field":{
                    name: 'distributor_id',
                    label: 'Customer',
                    display: _vm.data.distributor,
                    cdiType: 'search_my_users',
                    link: _vm.userLink,
                    permission: _vm.lockRules
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsLink',{attrs:{"val":_vm.data.company_id,"field":{
                    name: 'company_id',
                    label: 'Company',
                    display: _vm.data.company_name,
                    link: 'company'
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.data.distributor_po,"field":{
                    name: 'distributor_po',
                    label: 'Customer PO',
                    permission: _vm.lockRules
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.data.contractor_po,"field":{
                    name: 'contractor_po',
                    label: 'Contractor PO',
                    permission: _vm.lockRules
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.data.job_tag,"field":{
                    name: 'job_tag',
                    label: 'Job Tag',
                    permission: _vm.lockRules
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.data.sales_order_num,"field":{
                    name: 'sales_order_num',
                    label: 'Sales Order #',
                    permission: _vm.lockRules
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsSelect',{attrs:{"val":_vm.data.cdi_location_id,"field":{
                    name: 'cdi_location_id',
                    label: 'CDI Location',
                    options: _vm.cache.cdiLocationCache.CDI_LOCATIONS,
                    permission: _vm.lockRules
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.data.priority_code,"field":{
                    name: 'priority_code',
                    label: 'Priority',
                    permission:  (_vm.data.permissions && _vm.data.permissions.priority_code || _vm.lockRules)
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"val":_vm.data.tracker_date,"field":{
                    name: 'tracker_date',
                    label: 'Order Date',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"val":_vm.data.scheduled_ship,"field":{
                    name: 'scheduled_ship',
                    label: 'Scheduled Ship',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"val":_vm.data.actual_ship,"field":{
                    name: 'actual_ship',
                    label: 'Actual Ship',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"val":_vm.data.sch_receive_ship,"field":{
                    name: 'sch_receive_ship',
                    label: 'Scheduled Delivery',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsStatic',{attrs:{"val":_vm.data.receive_ship,"field":{
                    name: 'receive_ship',
                    label: 'Delivered',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsLink',{attrs:{"val":_vm.data.quote_id,"field":{
                    name: 'quote_id',
                    label: 'Quote ID',
                    link: 'quote',
                    display: _vm.data.quote_id
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsButton',{attrs:{"val":_vm.data.sales_users,"field":{
                    name: 'sales_users',
                    label: 'Sales Reps',
                    display: _vm.data.sales_users,
                    buttonLabel: 'Refresh Salespeople',
                    endPoint: 'refresh_salespeople',
                    refresh: true,
                    permission: _vm.lockRules
                }},on:{"post":_vm.post}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsDate',{attrs:{"val":_vm.data.eng_due_date,"field":{
                    name: 'eng_due_date',
                    label: 'Engineering Due Date',
                    permission: (_vm.data.permissions && _vm.data.permissions.eng_due_date || _vm.lockRules)
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.data.measured,"field":{
                    name: 'measured',
                    label: 'Measured',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.data.has_special_materials,"field":{
                    name: 'has_special_materials',
                    label: 'Special Materials',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.data.hold,"field":{
                    name: 'hold',
                    label: 'Hold Production',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsCheckbox',{attrs:{"val":_vm.data.stock,"field":{
                    name: 'stock',
                    label: 'CDI Stock',
                }},on:{"save":_vm.save}})],1),_c('div',{staticClass:"col-lg-4 col-sm-6 col-xs-12"},[_c('DetailsText',{attrs:{"val":_vm.data.stamp_cost,"field":{
                    name: 'stamp_cost',
                    label: 'Stamp Charges',
                }},on:{"save":_vm.save}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }