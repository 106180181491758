<template>
    <div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th>Line</th>
                    <th class="number">Qty</th>
                    <th>Description</th>
                    <th class="number">Price</th>
                    <th class="number">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="invoiceItem in invoice.invoice_items" v-bind:key="invoiceItem.tracker_invoice_id">
                    <td>
                        <div v-show="showForm(invoiceItem.tracker_invoice_id)">
                            <span class="glyphicon glyphicon-ok cdi_link spacing_right"
                                  @click="saveInvoiceItem()"></span>
                        </div>
                        <div v-if="!canEdit">
                            <span class="glyphicon glyphicon-lock cdi_link spacing_right"></span>
                        </div>
                        <div v-if="canEdit && !showForm(invoiceItem.tracker_invoice_id)">
                            <span class="glyphicon glyphicon-pencil cdi_link spacing_right"
                                  @click="editInvoiceItem(invoiceItem)"></span>
                            <span v-if="!invoiceItem.toshop" class="glyphicon glyphicon-trash cdi_link spacing_right"
                                  @click="setDeleteConfirmation(invoiceItem.tracker_invoice_id)"></span>
                        </div>
                    </td>
                    <td>{{ invoiceItem.line }}</td>
                    <td class="number">
                            <span v-show="!showForm(invoiceItem.tracker_invoice_id)">
                                {{ invoiceItem.qty }}
                            </span>
                        <span v-show="showForm(invoiceItem.tracker_invoice_id)">
                                <input type="text" class="text-right form-control" v-model="quantity"
                                       v-on:keyup.enter="saveInvoiceItem()"/>
                            </span>
                    </td>
                    <td>
                            <span v-show="!showForm(invoiceItem.tracker_invoice_id) || !descriptionEditable">
                                {{ invoiceItem.description }}
                            </span>
                        <span v-show="showForm(invoiceItem.tracker_invoice_id) && descriptionEditable">
                                <input class="form-control" type="text" v-model="description"
                                       v-on:keyup.enter="saveInvoiceItem()"/>
                            </span>
                    </td>
                    <td class="number">
                        <div v-show="!showForm(invoiceItem.tracker_invoice_id)">
                            ${{ invoiceItem.final_price }}
                        </div>
                        <div class="input-group" v-show="showForm(invoiceItem.tracker_invoice_id)">
                            <div class="input-group-addon">$</div>
                            <input type="text" class="text-right form-control" v-on:keyup.enter="saveInvoiceItem()"
                                   v-model="finalPrice"/>
                        </div>
                    </td>
                    <td class="number">${{ invoiceItem.total_price }}</td>
                </tr>
                <tr v-if="canEdit">
                    <td>
                        <div>
                            <span class="glyphicon glyphicon-ok cdi_link spacing_right"
                                  @click="saveNewInvoiceItem()"></span>
                        </div>
                    </td>
                    <td>ADD</td>
                    <td class="number">
                            <span>
                                <input type="text" class="text-right form-control"
                                       v-on:keyup.enter="saveNewInvoiceItem()" v-model="quantityAdd"/>
                            </span>
                    </td>
                    <td>
                            <span>
                                <input type="text" class="form-control" v-model="descriptionAdd"
                                       v-on:keyup.enter="saveNewInvoiceItem()"/>
                            </span>
                    </td>
                    <td class="number">
                        <div class="input-group">
                            <div class="input-group-addon">$</div>
                            <input type="text" class="text-right form-control" v-model="finalPriceAdd"
                                   v-on:keyup.enter="saveNewInvoiceItem()"/>
                        </div>
                    </td>
                    <td></td>
                </tr>
                </tbody>
                <tbody>
                <tr>
                    <td colspan="5" class="right">Sub-Total</td>
                    <td class="number">{{ '$' + invoice.total_cost }}</td>
                </tr>
                <tr v-for="surcharge in invoice.surcharges" v-bind:key="surcharge.surcharge_label">
                    <td colspan="5" class="right">{{ surcharge.surcharge_label }}</td>
                    <td class="number">{{ '$' + surcharge.surcharge_amount }}</td>
                </tr>
                <tr v-for="discount in invoice.discounts" v-bind:key="discount.tracker_discount_id">
                    <td></td>
                    <td colspan="4" class="right">{{ discount.discount_label }} <span v-if="!creditCardLock"
                            class="glyphicon glyphicon-trash cdi_link" v-show="appStore.isStaff()"
                            @click="deleteTotalDiscount(discount)"></span></td>
                    <td class="number" v-if="discount.discount_amount_dollars > 0">
                        {{ '-$' + discount.discount_amount_dollars }}
                    </td>
                    <td class="number" v-if="discount.discount_amount_pct > 0">
                        {{ '-' + discount.discount_amount_pct + '%' }}
                    </td>
                </tr>
                <tr>
                    <td colspan="5" class="right">Tax</td>
                    <td v-if="invoice.tax" class="number">{{ '$' + invoice.tax }}</td>
                    <td v-else class="number">{{ '$' + '0.00' }}</td>
                </tr>
                <tr>
                    <td colspan="5" class="right">Total</td>
                    <td class="number">{{ '$' + invoice.total_amount }}</td>
                </tr>
                <tr>
                    <td colspan="5" class="right">Paid</td>
                    <td class="number">{{ '$' + invoice.paid_amount }}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <ConfirmBox
                v-if="showConfirm"
                :heading="'Delete'"
                :body="'Would you like to delete this invoice item?'"
                @close="deleteInvoiceItem"
        />

    </div>
</template>

<script>
import {store} from '@/store/BusinessObject.store';
import ConfirmBox from '@/components/utils/ConfirmBox';
import appStore from "@/store/App.store";

export default {
    name: 'InvoiceItems',
    components: {ConfirmBox},
    props: ['invoice', 'creditCardLock'],
    data() {
        return {
            trackerInvoiceId: '',   // params[tracker_invoice_id]

            // Edit Item Form
            quantity: '',           // params[qty]
            finalPrice: '',         // params[final_price]
            description: '',        // params[description]

            // New Item Form
            quantityAdd: '',        // params[qty]
            finalPriceAdd: '',      // params[final_price]
            descriptionAdd: '',     // params[description]

            curbId: '',
            selectedTrackerInvoice: '',
            selectedDelete: '',
            descriptionEditable: false,
            showConfirm: false,
        }
    },
    methods: {
        saveInvoiceItem: function () {
            if (this.save(this.trackerInvoiceId, this.quantity, this.finalPrice, this.description)) {
                this.setFormModel();
            }
        },
        saveNewInvoiceItem: function () {
            if (this.save('', this.quantityAdd, this.finalPriceAdd, this.descriptionAdd)) {
                this.finalPriceAdd = '';
                this.quantityAdd = '';
                this.descriptionAdd = '';
            }
        },
        save: function (trackerInvoiceId, quantity, finalPrice, description) {
            let valid = true;
            if (quantity.trim() === '' || finalPrice.trim() === '' || quantity < 1 || isNaN(quantity)) {
                valid = false;
            }
            if (valid) {
                let params = {
                    'params[tracker_invoice_id]': trackerInvoiceId,
                    'params[qty]': quantity,
                    'params[final_price]': finalPrice,
                    'params[description]': description,
                };

                store.api('save_invoice_item', params).then(result => this.$emit('updateInvoice', result));
                this.selectedTrackerInvoice = '';

                return true;
            } else {
                return false;
            }

        },
        editInvoiceItem: function (invoice) {
            this.setFormModel(invoice);
            this.descriptionEditable = (invoice.curb_id === 0) ? true : false;
        },
        setDeleteConfirmation: function (tracker_invoice_id) {
            this.selectedDelete = tracker_invoice_id;
            this.showConfirm = true;
        },
        deleteInvoiceItem: function (confirmed) {
            if (confirmed) {
                store.api('delete_invoice_item', {'params': this.selectedDelete}).then(result => this.$emit('updateInvoice', result));
            }
            this.showConfirm = false;
            this.selectedDelete = '';
        },
        setFormModel(invoice = {}) {
            this.trackerInvoiceId = (invoice.tracker_invoice_id) ? invoice.tracker_invoice_id : '';
            this.quantity = (invoice.qty) ? invoice.qty.toString() : '';
            this.finalPrice = (invoice.final_price) ? invoice.final_price : '';
            this.description = (invoice.description) ? invoice.description : '';
            this.curb_id = (invoice.curb_id) ? invoice.curb_id : '';
            this.selectedTrackerInvoice = (invoice.tracker_invoice_id) ? invoice.tracker_invoice_id : '';
        },
        showForm: function (trackerInvoiceId) {
            return (trackerInvoiceId === this.selectedTrackerInvoice);
        },
        deleteTotalDiscount(discount) {
            store.api('delete_total_discount', {'params': discount}).then(result => this.$emit('updateInvoice', result));

        }
    },
    computed: {
        appStore() {
            return appStore
        },
        canEdit: function () {
            let canEdit = true;
             if (this.invoice.locked || this.invoice.invoice_sent) {
                canEdit = false;
            }
            return canEdit;
        },

        surchargeTotal: function () {
            let total = 0;

            if (this.invoice.surcharges) {
                for (let i = 0; i < this.invoice.surcharges.length; i++) {
                    total += this.invoice.surcharges[i].surcharge_amount;
                }
            }

            return (total) ? total : 0;
        }
    }
}

</script>